@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }
  .rotate-enter {
    transform: rotate(90deg);
  }
  
  .rotate-enter-active {
    transform: rotate(0);
    transition: transform 300ms;
  }
  
  .rotate-exit {
    transform: rotate(0);
  }
  
  .rotate-exit-active {
    transform: rotate(-90deg);
    transition: transform 300ms;
  }

  .fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
  
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
@font-face {
    font-family: 'bebas-Kai';
    src: url('../src/assets/fonts/BebasKai.ttf') format('truetype');

}

@font-face {
    font-family: 'sofia-pro';
    src: url('../src/assets/fonts/SofiaProRegular.ttf') format('truetype');
    src: url('../src/assets/fonts/SofiaProSemiBold.ttf') format('truetype');
    src: url('../src/assets/fonts/SofiaProBold.ttf') format('truetype');

}

@font-face {
    font-family: 'awakenning';
    src: url('../src/assets/fonts/AWAKENNING.ttf');
}

.container {
    @apply w-full mx-auto min-h-[100vh] flex flex-col justify-between ;
}

@media screen and (width < 1440px) {
    .container {
        @apply w-[2560px] mx-auto min-h-[100vh] flex flex-col justify-between ;
    }
    
}

.i4ewOd-pzNkMb-haAclf{
    display: none !important;
    visibility: hidden !important;
}

.tracking-tighter {
    letter-spacing: -2.68px;
    line-height: -141px;
}


.big-text{
    font-size: 12rem;
    font-family: 'bebas-Kai';
    letter-spacing: -2.68px;
    line-height: -141px;
    margin: -70px;
    padding: 0;
}
.time-txt{
    font-size: 120px;
    line-height: -141px;
    margin: -30px ;
    padding: 0;
}
.time-txt2{
    font-size: 120px;
    line-height: -131px;
    margin: -30px ;
    padding: 0;
}
.time-txt3{
    font-size: 120px;
    line-height: 100px;
   
    padding: 0;
}
@media screen and (width < 1440px) {
    .time-txt{
        font-size: 100px;
        line-height: -141px;
        margin: -30px ;
        padding: 0;
    }
    .time-txt2{
        font-size: 100px;
        line-height: -131px;
        margin: -30px ;
        padding: 0;
    }
    .time-txt3{
        font-size: 100px;
        line-height: 100px;
       
        padding: 0;
    }
    
}

@media screen and (width < 1024px) {
    .time-txt{
        font-size: 80px;
        line-height: -141px;
        margin: -30px ;
        padding: 0;
    }
    .time-txt2{
        font-size: 80px;
        line-height: -131px;
        margin: -30px ;
        padding: 0;
    }
    .time-txt3{
        font-size: 80px;
        line-height: 100px;
       
        padding: 0;
    }
    
}


@media screen and (width < 768px) {
    .time-txt{
        font-size: 60px;
        line-height: -141px;
        margin: -30px ;
        padding: 0;
    }
    .time-txt2{
        font-size: 60px;
        line-height: -131px;
        margin: -30px ;
        padding: 0;
    }
    .time-txt3{
        font-size: 60px;
        line-height: 100px;
       
        padding: 0;
    }
    
}


@media screen and (width < 640px) {


    .big-text{
        font-size: 6rem;
        font-family: 'bebas-Kai';
        letter-spacing: -2.68px;
        line-height: -141px;
        margin: -30px;
        padding: 0;
    }


    .time-txt{
        font-size: 40px;
        line-height: -141px;
        margin: -30px ;
        padding: 0;
    }
    .time-txt2{
        font-size: 40px;
        line-height: -131px;
        margin: -30px ;
        padding: 0;
    }
    .time-txt3{
        font-size: 40px;
        line-height: 100px;
       
        padding: 0;
    }
    
}




.btn{
    border: 2px solid #FB562A;
    color: #FB562A;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.btn::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #FB562A;
    top: 0px;
    left: 0;
    transition: all 0.5s ease-in-out;
    /* z-index: -1; */
}
.btn:hover::before{
    top: -5px;
    left: -5px;
}

.btn:hover{
    color: white;
    background: #FB562A;
}


.btn1{
    border: 2px solid #ffffff;
    color: #ffffff;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.btn1::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #ffffff;
    top: 0px;
    left: 0;
    transition: all 0.5s ease-in-out;
    /* z-index: -1; */
}
.btn1:hover::before{
    top: -5px;
    left: -5px;
}

.btn1:hover{
    color: #2A9444;
    background: #ffffff;
}

.btn2{
    border: 2px solid #47B500;
    color: #47B500;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.btn2::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #47B500;
    top: 0px;
    left: 0;
    transition: all 0.5s ease-in-out;
    /* z-index: -1; */
}
.btn2:hover::before{
    top: -5px;
    left: -5px;
}

.btn2:hover{
    color: #47B500;
    background: #47B500;
}


.black-btn2{
    border: 2px solid #000000;
    color: #000000;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.black-btn2::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #000000;
    top: 0px;
    left: 0;
    transition: all 0.5s ease-in-out;
    /* z-index: -1; */
}
.black-btn2:hover::before{
    top: -5px;
    left: -5px;
}

.black-btn2:hover{
    color: #ffffff;
    background: #000000;
}
.button{
    border-width: 2px;
    border-style: solid;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.button::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-style: solid;
    top: 0px;
    left: 0;
    transition: all 0.5s ease-in-out;
    /* z-index: -1; */
}
.button:hover::before{
    top: -5px;
    left: -5px;
    opacity: 0.5;
}

/* .button:hover{
    color: #47B500;
    background: #47B500;
} */

  .game-board {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 10px;
        max-width: 1440px;
        margin: 0 auto;
        margin-top: 8px;
    }
    
    .card {
        width: 100%;
        height: 150px;
        background-color: #ffffff;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        perspective: 1000px;
        transition: transform 0.5s;
    }
    
    .card.flipped {
        transform: rotateY(-180deg);
    }
    
    .card img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .card.selected {
        background-color: #f3f3f3;
    }
    
    .card.matched {
        background-color: #4caf50;
        border-color: #4caf50;
        color: #fff;
    }

    .cardimg{
        /* i want to rotate the image so it's the right perspective */
        transform: rotateY(180deg);
    }

@media screen and (width < 768px) {
    .game-board {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        max-width: 1440px;
        margin: 0 auto;
        margin-top: 8px;
    }
}
@media screen and (width < 468px) {
    .game-board {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 10px;
        max-width: 1440px;
        margin: 0 auto;
        margin-top: 8px;
    }

    .card {
        width: 100%;
        height: 75px;
        background-color: #ffffff;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        perspective: 1000px;
        transition: transform 0.5s;
    }
}
    

    .h-screeen div > h2{
        font-size: 6rem;
        font-family: 'bebas-Kai';
        line-height: 100px;
        
    }
    @media screen and (width < 768px) {
        .h-screeen div > h2{
            font-size: 5rem;
            font-family: 'bebas-Kai';
            line-height: 70px;margin-bottom: 20px;
        }
        
    }
    .box1{
        background: url('../src/assets/mask36.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 1 ;
        background-color: #2A9444;
      background-blend-mode: multiply;

    }
    .box2{
        background: url('../src/assets/mask32.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: #0000007d;
        background-blend-mode: multiply;
        
    }

   


    .box2:hover > h2 {
        bottom: 50%;
        transform: translateY(0%);
        
    }
    .box1:hover > h2 {
        bottom: 50%;
        transform: translateY(0%);

    }
    .box2:hover  p {
        opacity: 1;
        bottom: 30% ;
        transform: translateY(0%);
    }
    .box1:hover  p {
        opacity: 1;
        bottom: 18% ;
        transform: translateY(0%);
        z-index: 1 ;
    }
    .box1 h2{
        z-index: 2 ;
    }
    
    .youth-div{
        background: url(../src/assets/youthbg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: #0000007d;
        background-blend-mode: multiply;
    }

    .product-h2{
        text-wrap: balance;
    }
   

   
.product-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    perspective: 1000px;
    transition: transform 0.5s;
}


.custom-carousel {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
   
  }
  
  .carousel-content {
    padding: 20px;
    height: 100%;
    transition: all 0.5s ease-in-out;
  }
  
  .carousel-image {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .carousel-image img{
    max-width: 100%;
    height: 100%;
    font-style: italic;
    vertical-align: middle;

  }
  .main-image-1, .main-image-2, .main-image-4, .main-image-7, .main-image-8, .main-image-10, .main-image-12{
    max-width: 100%;
    width: 450px;
  }
  .main-image-3, .main-image-6, .main-image-9,  .main-image-11{
    max-width: 100%;
    width: 550px;
  }
  
  .carousel-arrows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
 
  .arrow-btn2{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* left: 19%; */
    right: 0 ;
    z-index: 1;
  }
  .arrow-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* right: 17%; */
    z-index: 1;
  }

  .left-arrow{
    transform: rotate(180deg);
    width: 70px;
  }
  .right-arrow{
    width: 70px;
  }

  .pdesc{
    /* replace \n with a line break */
    white-space: pre-line;

  }

.about-us{
    background: url(../src/assets/game-section.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    position: relative;
    z-index: 1;
}

.about-us::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    opacity: 0.5;
    z-index: -1;
}

  .vision{
    background-color: #2a9444f9;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    position: relative;
  }

  .vision::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../src/assets/green-stripe.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    opacity: 0.5;
  }

  .career{
    background: url(../src/assets/careerbg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;

  }

  .career2{
    /* margin-top: -10.5rem; */
    background-image: url(../src/assets/careerbg2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
   
  }
  .career-text{
    font-size: 5.3rem;
    font-family: 'bebas-Kai';
    line-height: 100px;
  }

  .contactus{
    background: url(../src/assets/contactus.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    object-fit: contain;
  }

.products{
    background: url(../src/assets/productsbg.png);
}

@media screen and (width <= 768px) {
    .products{
        background: url(../src/assets/productsbg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        object-fit: contain;
    }

    .product-category{
        grid-template-columns: repeat(2, 1fr);
    }
    .custom-carousel{
        flex-direction: column;
    }

    .carousel-content{
        padding: 0px;
    }

    .carousel-image{
        margin-top: 20px;
        max-width: 300px;
    }

    .arrow-btn2{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0%;
        right: 0 ;
      }
      .arrow-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0%;
      }
      .career-text{
        font-size: 4.3rem;
        font-family: 'bebas-Kai';
        line-height: 70px;
      }
    
}
@media screen and (width < 468px) {
    .products{
        background: url(../src/assets/productsbg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        object-fit: contain;
    }

    .product-category{
        grid-template-columns: repeat(2, 1fr) !important;
        
    }
    .custom-carousel{
        flex-direction: column;
    }

    .carousel-content{
        padding: 0px;
       
    }

    .carousel-image{
        max-width: 450px;
        width: 100%;
    }

    .arrow-btn2{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5% !important;
        right: 0 ;
      }
      .arrow-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: -79%;
      }

      .career-text{
        font-size: 4.3rem;
        font-family: 'bebas-Kai';
        line-height: 70px;
      }
/* 
      .our-people{
        width: 50px;
        height: 100%;
        object-fit: cover;
        margin-right: -20px !important;
      }
        .our-people img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
     */
}



.group-pepsi{
    background: url(../src/assets/group-pepsi.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    object-fit: contain;
}
.pma{
    background: url(../src/assets/pma.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    object-fit: contain;
}
.pfa{
    background: url(../src/assets/PFA-SESSION.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    object-fit: contain;
}
.pepsi-btn{
    border: 2px solid #ffffff;
    color: #ffffff;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.pepsi-btn::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #ffffff;
    top: 0px;
    left: 0;
    transition: all 0.5s ease-in-out;
    /* z-index: -1; */
}

.pepsi-btn:hover::before{
    top: 8px;
    left: 6px;
}

.pepsi-btn:hover{
    color: #152A82;
    background: #ffffff;
}

@media screen and (width < 768) {
    .group-pepsi{
        
        background-size: 100% 100%;
        background-position: center center;
        object-fit: contain;
    }
    .pma{
     
        background-size: 100% 100%;
        background-position: center center;
        object-fit: contain;
    }
    .pfa{
       
        background-size: 100% 100%;
        background-position: center center;
        object-fit: contain;
    }
}



@keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(-2%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes shrink {
    0% {
      width: 95%;
    }
    100% {
      width: 90%;
    }
  }


  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    width: 100%;
  }
  .rect-container{
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

  }

  
  .spinner {
    position: relative;
  }
  
  .logo {
    width: 100px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .border-circle {
    width: 200px; /* Adjust the width as needed */
    height:200px; /* Adjust the height as needed */
    border: 16px solid transparent;
    border-top-color: #ff0000; /* Replace with your first color */
    border-right-color: #00ff00; /* Replace with your second color */
    border-bottom-color: #c8ff00; /* Replace with your third color */
    border-left-color: #ffffff; /* Replace with your fourth color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }


  .people-card{
   position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    background:url(../src/assets/green-stripe.png);
    background-blend-mode: multiply;
    z-index: 0;
  }

  /* .slider-container{
    position: relative;
    width: 100%;
    height: 100%;
  
  }

  .slick-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .slick-prev{
    left: 0;
  }

  .slick-next{
    right: 40px;
  } */





::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #2A9444;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}
